.App {
  background: rgb(227, 227, 227);
}

.header-row {
  background-color: #2DA457;
}

.header-text{
  color: #fff;
}

.content-page-header-row {
  border-bottom: 1px solid rgba(0,0,0,0.5);
}

.side-navigation-bar {
  min-height: 100vh;
  border-right: 1px solid rgba(0,0,0,0.5);
}

.submit-button {
  color: #fff !important;
  background-color: #2DA457 !important;
}

.danger-button {
  color: #fff !important;
  background-color: #d9534f !important;
}

.content-card {
  margin: 10px !important;
  height: 100px;
  background-color: #fff !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-card .header {
  color: #2DA457 !important;
  font-size: 30px !important;
  text-align: center;
}